import React from "react";
import "./TeamInformation.css";

function TeamInformation({ teamDetails }) {
  const { image, name, role, details } = teamDetails;

  return (
    <>
      <div style={{ textDecoration: "none" }}>
        <div className="teamMemberCard">
          <img id="teamMemberImage" src={image} />
          <div className="name">{name}</div>
          <div className="role">{role}</div>
        </div>
      </div>
    </>
  );
}

export default TeamInformation;
