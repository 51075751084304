import React from "react";
import "./InstagramEmbed.css";
import InstagramEmbed from "react-instagram-embed";

function InstagramEmbedTab() {
  return (
    <>
      <div className="container instagramCard">
        <div className="row">
          <div className="col-md-4">
            <InstagramEmbed
              url="https://www.instagram.com/p/Cj3KHG9gEpM/?hl=en"
              style={{ borderRadius: "1em" }}
            />
          </div>
          <div className="col-md-4">
            <InstagramEmbed
              url="https://www.instagram.com/p/CjNlNnxgeSI/?hl=en"
              style={{
                borderRadius: "1em",
              }}
            />
          </div>
          <div className="col-md-4">
            <InstagramEmbed
              url="https://www.instagram.com/p/CiucnGzA8x2/?hl=en"
              style={{ borderRadius: "1em" }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default InstagramEmbedTab;
