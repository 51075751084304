import React from "react";
import "./Statistics.css";

function Statistics() {
  return (
    <>
      <div className="container stats">
        {" "}
        <div className=" stats">
          <div className="row">
            <div className="col-md-4 statBox">
              <h2>30+</h2>
              Projects
            </div>
            <div className="col-md-4 statBox">
              <h2>87k</h2>
              Instagram Followers
            </div>
            <div className="col-md-4 statBox">
              <h2>4.7</h2>
              Avg. Google Rating
            </div>
            <div className="col-md-4 statBox">
              <h2>50+</h2>
              In-house & Contractors Team
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Statistics;
