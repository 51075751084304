import Carousel from "react-bootstrap/Carousel";
import coverImage1 from "../../assets/imgs/cover2.jpg";
import coverImage2 from "../../assets/imgs/7.png";
import coverImage3 from "../../assets/imgs/1.png";
import coverImage4 from "../../assets/imgs/2.png";
import coverImage5 from "../../assets/imgs/5.png";
import coverImage6 from "../../assets/imgs/6.png";
import coverImage7 from "../../assets/imgs/3.png";
import coverImage8 from "../../assets/imgs/4.png";
import "./Carousel.css";

function CarouselImage() {
  return (
    <Carousel style={{ marginBottom: "1rem" }}>
      <Carousel.Item interval={4000} className="parallax">
        <img
          className="d-block w-100"
          style={{
            height: "65vh",
            objectFit: "cover",
            filter: "brightness(60%)",
          }}
          src={coverImage1}
          alt="First slide"
        />
        <Carousel.Caption>
          <h4 className="slideLabel">Customized designs and execution</h4>
          <p>We prioritize your preferences to enrich the experience</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          style={{
            height: "65vh",
            objectFit: "cover",
            filter: "brightness(80%)",
          }}
          src={coverImage2}
          alt="Second slide"
        />
        <Carousel.Caption>
          <h4 className="slideLabel">Dream homes begin with dream designs</h4>
          <p>We are here to make your dreams come to reality</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          style={{
            height: "65vh",
            objectFit: "cover",
            filter: "brightness(80%)",
          }}
          src={coverImage3}
          alt="Third slide"
        />
        <Carousel.Caption>
          <h4 className="slideLabel">Interiors that fit your budget</h4>
          <p>Experience unmatched quality & timely delivery</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={4000} className="parallax">
        <img
          className="d-block w-100"
          style={{
            height: "65vh",
            objectFit: "cover",
            filter: "brightness(80%)",
          }}
          src={coverImage4}
          alt="First slide"
        />
        <Carousel.Caption>
          <h4 className="slideLabel">Customized designs and execution</h4>
          <p>We prioritize your preferences to enrich the experience</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          style={{
            height: "65vh",
            objectFit: "cover",
            filter: "brightness(80%)",
          }}
          src={coverImage5}
          alt="Second slide"
        />
        <Carousel.Caption>
          <h4 className="slideLabel">Dream homes begin with dream designs</h4>
          <p>We are here to make your dreams come to reality</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselImage;
