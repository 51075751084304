import React from "react";
import { Link } from "react-router-dom";

function ProjectPageHeader() {
  return (
    <>
      <img
        src="https://www.nipponpaint.co.in/wp-content/uploads/2019/04/10-Amazing-Wall-Texture-Designs-To-Revive-Your-Living-Room.jpg"
        style={{
          width: "100%",
          height: "12vh",
          objectFit: "cover",
          filter: "brightness(25%)",
        }}
      />
      <div
        className="centered"
        style={{
          letterSpacing: "0.25em",
          textTransform: "uppercase",
          fontWeight: "bold",
        }}
      >
        Project
      </div>
      <div className="bottom-left">
        <Link
          to="/"
          style={{ textDecoration: "underline", color: "whitesmoke" }}
        >
          {" "}
          <span>Home</span>{" "}
        </Link>
        &nbsp;/&nbsp; Project
      </div>
    </>
  );
}

export default ProjectPageHeader;
