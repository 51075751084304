import React, { useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SaContext from "../Dashboard/SAContext";

export default function ModalForm() {
  const { formSubmit, setFormSubmit } = useContext(SaContext);

  const handleClose = () => setFormSubmit(false);

  return (
    <>
      <Modal show={formSubmit} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>We have received your request!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Thank you for reaching out to us!
          <br />
          We will get back to you shortly.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Thank You
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
