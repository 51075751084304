import React from "react";
import "./YouTubeCard.css";
import YoutubeEmbed from "./YouTubeEmbed";

function YouTubeCard() {
  return (
    <>
      <div className="container  mobile-card-container youTubeCard">
        <div className="row  flex-nowrap flex-sm-wrap">
          <div className="col-md-4">
            <YoutubeEmbed embedId="lx1jMAFOC9E" />
          </div>
          <div className="col-md-4">
            <YoutubeEmbed embedId="03npqCmb5ho" />
          </div>
          <div className="col-md-4">
            <YoutubeEmbed embedId="KWdikUe2Zt8" />
          </div>
        </div>
      </div>
    </>
  );
}

export default YouTubeCard;
