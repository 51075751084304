import "./App.css";
import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Dashboard/Home";
import NavigationBar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Toaster from "./components/Toaster/Toaster";
import SaContext from "./components/Dashboard/SAContext";
import ProjectView from "./components/ProjectView/ProjectView";

function App() {
  const projectCards = {
    0: {
      title: "4 BHK Luxury Flat Design",
      subTitle: "Park Grandeur, Balewadi High Street",
      pagetitle:
        "Flat Interior Designing For Vishal & Shradha Raina at Balewadi",
      clientName: "Vishal & Shradha Raina",
      siteLocation: "Park Grandeur, Balewadi",
      siteType: "Residential House",
      siteSummary:
        "Situated at Balewadi high street, Pune on the 18th floor, the site is set in an urban colony. The owners, a young couple wanted a contemporary, bold yet minimal home. We wanted the house to look lavish but contemporary, with lots of monochrome marble finish textures. Interestingly. Clean lines, uncluttered simplicity, bold tones. Art meets craft, colour meets character and traditional design meets modern aesthetics. This project creates a sophisticated look with carefully-selected pastel hues; curated furniture and furnishings; and hand-picked artworks, lighting fixtures and accessories.",
      googleDriveLink: "1d0cMbVefOqnVPEYR0ycNs3H82rC8Bd35",
    },
    1: {
      title: "3 BHK Flat Design",
      subTitle: "Alive Skypark, Baner",
      pagetitle: "Flat Interior Designing For Mrs. Diksha and family at Baner",
      clientName: "Diksha",
      siteLocation: "Alive Skypark, Baner",
      siteType: "Residential House",
      siteSummary:
        "Designed and executed by sainteriorscapes the 1,100 sq ft, 3BHK alive sky park is located in suburban Pune. A warm and cosy 3BHK duplex for a young couple and their parents. As you enter the main home, the long living-dining area is broken into two halves with a ‘elliptical ceiling-to-floor curtain, creating a visual surprise to the eye. This brings newness to the otherwise usual rectangular structures of the room. The distinct division between the two parts in finishes, surface patterns and levels of both the walls and ceiling is enhanced. Each space is a canvas for bold usage of colors and patterns, which is evidently visible in areas like the kitchen or dining, paired with subtle soothing colors. Geometric work, or patterned fabrics and/or wallpapers, subsequently set a flow of many spaces in the house.",
      googleDriveLink: "1rhyFigBYXJoEDwA7s-FtsrEs15jaOuzh",
    },
    2: {
      title: "3 BHK Luxury House Design",
      subTitle: "Keshav Nagar, Pune",
      pagetitle:
        "Flat Interior Designing For Amandeep & Ramanpreet at Keshav Nagar",
      clientName: "Amandeep Singh",
      siteLocation: "Florida Watercolour, Keshav Nagar",
      siteType: "Residential House",
      siteSummary:
        "Soni’s, an elegant modern classic 1500 sq. Ft. Flat in Pune, flaunts all the grace of the alluring bloom. The interiors were crafted in alignment with the client’s request for a well-organized space, calm and minimal interiors with a soothing color palette. The space had to be graceful and inspiring, matching the personality of family members.The dining room is an eye-catcher of the living room, where the colors of the dining table and the counter complement each other perfectly. In the dining room, a grey Italian marble top is paired with bold chairs. The beautiful pendant lights adorn the wooden ceiling, which is perfectly demarcating with the profile light. Parents' bedroom features shades of clay tones with classic window seating detailed with mouldings and a quirky capsule mirror against a subtle muted panelling. The balcony gives a complete break of fresh tropical painting with a vertical green arch.",
      googleDriveLink: "1nIZZ8ab1yiTL4HfFrWaoMjkhVJbnuE7s",
    },
    3: {
      title: "House Design",
      subTitle: "Sukhwani Callisto, Wakad",
      pagetitle: "Flat Interior Designing For Ritu & Jayesh Sharma at Wakad",
      clientName: "Ritu & Jayesh Sharma",
      siteLocation: "Sukhwani Callisto, Wakad",
      siteType: "Residential House",
      siteSummary:
        "The home is inhabited by Jayesh and Ritu, accompanied by their son aged 14.  Built over 1,200 sq ft, this flat had to be designed to please and soothe every eye. So, we created a with the help of a modern classic theme. For the living room, we took classic moulding to create a TV wall with linear grooves at the tv ledge. In, for the dining space, we used bottle green chairs with round dining table for three with a marble top dining table with the fluted details below gave a modern and luxe feeling. The moulded square walls added an interesting contrast to the softness. We kept the bedroom theme modern and contemporary with maximum utilization of space.",
      googleDriveLink: "15gGwDD1zeL-RMpiljUWOavum2uuM6VfO",
    },
    4: {
      title: "2 BHK Flat Design",
      subTitle: "702, Legacy Bliss, Hinjewadi",
      pagetitle:
        "Flat Interior Designing For Vishwa & Riga Chauhan At Hinjewadi",
      clientName: "Vishwa & Riga Chauhan",
      siteLocation: "702, Legacy Bliss, Hinjewadi",
      siteType: "Residential House",
      siteSummary:
        "Situated at Balewadi high street, Pune on the 18th floor, the site is set in an urban colony. The owners, a young couple wanted a contemporary, bold yet minimal home. We wanted the house to look lavish but contemporary, with lots of monochrome marble finish textures. Interestingly. Clean lines, uncluttered simplicity, bold tones. Art meets craft, colour meets character and traditional design meets modern aesthetics. This project creates a sophisticated look with carefully-selected pastel hues; curated furniture and furnishings; and hand-picked artworks, lighting fixtures and accessories.",
      googleDriveLink: "1J8ZrSHkXvRz15T5d2VZ7NX22kwZ-DWWZ",
    },
    5: {
      title: "2 BHK Flat Design",
      subTitle: "502, Legacy Bliss, Hinjewadi",
      pagetitle: "Flat Interior Designing For Vishal & Palak At Hinjewadi",
      clientName: "Vishal & Palak Seth",
      siteLocation: "502, Legacy Bliss, Hinjewadi",
      siteType: "Residential House",
      siteSummary:
        "Situated at Balewadi high street, Pune on the 18th floor, the site is set in an urban colony. The owners, a young couple wanted a contemporary, bold yet minimal home. We wanted the house to look lavish but contemporary, with lots of monochrome marble finish textures. Interestingly. Clean lines, uncluttered simplicity, bold tones. Art meets craft, colour meets character and traditional design meets modern aesthetics. This project creates a sophisticated look with carefully-selected pastel hues; curated furniture and furnishings; and hand-picked artworks, lighting fixtures and accessories.",
      googleDriveLink: "1Tv_-TcuwaTCJ7kg2S014WgwzQxnyV6QQ",
    },
  };

  const [formSubmit, setFormSubmit] = useState(false);
  const [currentProject, setCurrentProject] = useState(0);

  return (
    <>
      <SaContext.Provider
        value={{ formSubmit, setFormSubmit, currentProject, setCurrentProject }}
      >
        <NavigationBar />
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route
              exact
              path="/project"
              element={
                <ProjectView projectDetails={projectCards[currentProject]} />
              }
            />
          </Routes>
        </BrowserRouter>
        <Footer />
        {formSubmit && <Toaster />}
      </SaContext.Provider>
    </>
  );
}

export default App;
