import React from "react";
import "./HorizontalScrollbar.css";

function HorizontalScrollbar6() {
  return (
    <>
      <div class="test">
        <div>
          <span id="first" class="border-change circle"></span>
        </div>
        <div>
          <span id="second" class="circle"></span>
        </div>
        <div>
          <span id="third" class="circle"></span>
        </div>
        <div>
          <span id="fourth" class="circle"></span>
        </div>
        <div>
          <span id="five" class="circle"></span>
        </div>
        <div>
          <span id="six" class="circle"></span>
        </div>
      </div>
    </>
  );
}

export default HorizontalScrollbar6;
